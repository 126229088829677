<template>
  <div>
    <v-row>
      <!-- basic -->
      <v-col cols="12">
        <v-card>
          <div class="w-full boxed-container">
            <div class="mr-6 mb-4 align-center">
              <v-row>
                <v-col sm="8" cols="12">
                  <v-card-title>Recently Expired User</v-card-title>
                </v-col>
                <v-col sm="4" class="pl-4 mt-3" cols="12">
                  <v-text-field
                    v-model="search"
                    rounded
                    dense
                    outlined
                    :prepend-inner-icon="icons.mdiMagnify"
                    class="flex-grow-0 app-bar-search"
                    hide-details
                    @keyup.enter="init(1)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Id</th>
                  <th class="text-center">Name</th>
                  <th class="text-center">Phone</th>
                  <th class="text-center">Designation</th>
                  <th class="text-center">Role</th>
                  <th class="text-center">User Type</th>
                  <th class="text-center">Plan Start Date</th>
                  <th class="text-center">Plan End Date</th>
                  <th class="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td class="text-center">
                    {{ item.name == null || item.lname == null ? ' - ' : item.name + ' ' + item.lname }}
                  </td>
                  <td class="text-center">
                    {{ item.mobile }}
                  </td>
                  <td class="text-center">
                    {{ item.designation == null ? '-' : item.designation }}
                  </td>
                  <td class="text-center">
                    {{ item.user_type == 1 ? 'Admin' : item.user_type == 2 ? 'User' : 'Designer' }}
                  </td>
                  <td class="text-center">
                    {{ item.type }}
                  </td>
                  <td class="text-center">
                    {{ item.plan_start_date }}
                  </td>
                  <td class="text-center">
                    {{ item.plan_end_date }}
                  </td>
                  <td class="text-center">
                    {{ item.status == 1 ? 'Active' : 'Pending' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="!load" id="app">
            <pagination
              :total-pages="paginate.total_pages"
              :per-page="paginate.per_page"
              :current-page="paginate.current_page"
              @pagechanged="onPageChange"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { format, parseISO } from 'date-fns'
import Pagination from '../../components/Pagination.vue'

export default {
  name: 'UserList',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      paginate: {},
      search: null,
      load: true,
    }
  },
  created() {
    this.init(1)
  },
  methods: {
    init(page) {
      let url = `recentlyexplan?page=${page}`
      if (this.search) {
        url += `&search=${this.search}`
      }
      this.$api.get(url).then(response => {
        this.items = response.data.data
        console.log(this.items)
        this.paginate = response.data.pagination
        this.load = false
      })
    },
    onPageChange(page) {
      this.init(page)
    },
    formatDate(date) {
      return format(parseISO(new Date(date).toISOString()), 'dd-MM-yyyy')
    },
  },
}
</script>
